



































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { DialogBase, BrowserUtility, BrowserType } from "ui-gallery";
import { ProfileService } from "@/models/profile/ProfileService";
import Axios from "axios";
import ProfileListView from "../organisms/ProfileListView.vue";
import { SuccessResponse } from "@/models/api/Response";
import { Profile } from "@/models/profile/Profile";
import { container } from "tsyringe";
@Component({ components: { ProfileListView } })
export default class ProfileSelectDialog extends Mixins(DialogBase) {
    private profiles: Profile[] = [];
    private isShowProfile = false;
    private isFullScreen = false;
    private height = 0;

    @Prop({ default: true }) readonly isShowMyProfile!: boolean;

    // #region private getters
    /**
     * プロフィール情報に関するサービス
     */
    private readonly profileService: ProfileService = container.resolve(ProfileService);

    // #endregion

    // #region methods
    private async profileSelected(profile: Profile): Promise<void> {
    }

    private async created(): Promise<void> {
        window.addEventListener("resize", this.onWindowSizeChanged);
        this.onWindowSizeChanged();
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onWindowSizeChanged);
    }

    private onWindowSizeChanged() {
        this.height = window.innerHeight;
        this.isFullScreen = window.innerWidth <= 520 || BrowserUtility.hasFlag(BrowserType.ios);
    }

    /**
     * ダイアログを表示します．
     */
    public async show(): Promise<void> {
        this.isShowProfile = false;
        // ダイアログアニメーションが終了してグリッドのレスポンシブを更新する
        setTimeout(() => {
            const listView = this.$refs.listView as Vue[] | undefined;
            if (!listView) return;
            if (listView[0]) listView[0].$forceUpdate();
            if (listView[1]) listView[1].$forceUpdate();
            this.isShowProfile = true;
        }, 200);
        await this.showAsync();
    }

    /**
     * プロフィールが選択されたときに実行されます．
     */
    private onProfileSelected(profile: Profile) {
        this.$emit("profileSelected", profile);
        this.close();
    }

    /**
     * プレビューが選択されたときに実行されます．
     */
    private onPreview(profile: Profile) {
        this.$emit("preview", profile);
        this.close();
    }
    // #endregion
}
