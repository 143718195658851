






































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ScreenModeType } from "@/models/room/ConnectionService";
/**
 * 接続画面右側のコマンドバーを提供します．
 */
@Component
export default class extends Vue {
    /**
     * 現在の画面モード
     */
    private screenModeType = ScreenModeType;

    /**
     * 現在の画面モード
     */
    @Prop({ default: ScreenModeType.VideoChat }) readonly value!: ScreenModeType;

    /**
     * ビデオチャットボタンを表示するかどうか
     */
    @Prop({ default: true }) readonly isShowVideoChatModeButton!: boolean;
}
