














































import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import { delay } from "@/models/utilities/timer";
import { Subscription as RxSubscription, timer, Subject, Observable } from "rxjs";

import { container } from "tsyringe";
import { DrawingService } from "@/models/drawing/DrawingService";
import { animate } from "../../../models/utilities/animate";

import { fromEvent, from, of, merge } from "rxjs";
import { switchMap, map, bufferTime, delay as delayOp, mergeMap, filter, takeUntil, concatMap, pairwise } from "rxjs/operators";
import { DrawingModeType } from "../../../models/drawing/DrawingModeType";

interface Size { width: number; height: number; };

/**
 * 資料設定ページを提供します.
 */
@Component
export default class DrawingCanvas extends Vue {
    private isLoading = false;
    private isImgLoading = false;
    private isInit = false;
    private isShowImg = true;

    private drawingService = container.resolve(DrawingService);

    scale = 1;
    offsetX = 0;
    offsetY = 0;

    resizeSubject: Subject<void> = new Subject();
    loadImgHandler: Subject<void> = new Subject();
    scaleHandler: Subject<number> = new Subject();
    resetHandler = new Subject();

    @Ref() readonly container!: HTMLElement;

    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ default: "" }) readonly imageSrc!: string;
    @Prop({ default: "" }) readonly xdpId!: string;
    @Prop({ default: "" }) readonly docId!: string;
    @Prop({ default: true }) readonly isZoomEnabled!: boolean;


    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private created() {
        window.addEventListener("mousemove", this.onDrawMove);
        window.addEventListener("touchmove", this.onDrawMoveTouch);
        window.addEventListener("mouseup", this.onEndDrawing);
        window.addEventListener("touchend", this.onEndDrawing);
        window.addEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: KeyboardEvent) {
        if (e.keyCode === 46) {
            this.removeCurrentShape();
        }
    }

    @Watch("imageSrc")
    private async onImageSrcChanged(): Promise<void> {
        this.isImgLoading = true;
    }

    private async loaded() {
        if (!this.isInit) {
            this.isImgLoading = false;
            return;
        }

        this.isImgLoading = false;
        this.isLoading = false;

        this.loadImgHandler.next();
        this.resizeSubject.next();
    }

    @Ref() readonly image!: HTMLImageElement;

    private size = { width: 0, height: 0 };

    get drawAreaFrameElementStyle() {
        return {
            width: this.size.width + "px",
            height: this.size.height + "px"
        };
    }

    get drawAreaContentStyle() {
        const rect = this.drawAreaContent;
        return {
            top: rect.top + "px",
            bottom: rect.bottom + "px",
            left: rect.left + "px",
            right: rect.right + "px"
        };
    }

    get drawAreaContent() {
        const aspectRatio = this.getImageAspectRatio();
        const size = this.size;
        return {
            top: -(this.scale * size.height - size.height) + (size.height * this.offsetY),
            bottom: -(this.scale * size.height - size.height) - (size.height * this.offsetY),
            left: -(this.scale * size.width - size.width) + (size.width * this.offsetX),
            right: -(this.scale * size.width - size.width) - (size.width * this.offsetX)
        };
    }

    private getImageSize() {
        return {
            width: this.image.naturalWidth, height: this.image.naturalHeight,
        };
    }

    private getImageAspectRatio() {
        if (!this.image) return 1;
        const size = this.getImageSize();
        return size.width / size.height;
    }

    private getOwnSize() {
        if (!this.$el) {
            return {
                width: 0,
                height: 0
            };
        }
        const rect = this.$el.getBoundingClientRect();
        return {
            width: rect.width,
            height: rect.height
        };
    }

    resetZoom() {
        this.scale = 1;
        this.offsetX = 0;
        this.offsetY = 0;
        this.resetHandler.next();
    }

    plus() {
        this.scaleHandler.next(-360);
    }

    minus() {
        this.scaleHandler.next(360);
    }

    /**
     * リアクティブイベントの初期化
     */
    private initObservable() {
        const resizeEvent = merge(
            this.resizeSubject,
            fromEvent(window, "resize")
        ).pipe(
            map(_ => {
                const ownSize = this.getOwnSize();
                const imgRatio = this.getImageAspectRatio();

                const strechedWidth = ownSize.height * imgRatio;
                // 画像を縦いっぱいに広げたときに横がはみ出さなければ
                if (strechedWidth < ownSize.width) {
                    return { width: strechedWidth * 0.9, height: ownSize.height * 0.9 };
                }
                return { width: ownSize.width * 0.9, height: ownSize.width / imgRatio * 0.9 };
            })
        );

        // this.container.addEventListener("mousedown", _ => alert(""));

        const scaleEvent = merge(fromEvent(this.container, "wheel").pipe(
            // ゲストはズームさせるかどうかを切り替える必要があるため
            filter(_ => this.isZoomEnabled),
            filter(_ => this.drawingService.drawingModeType === DrawingModeType.Move),
            map((e: any) => e.deltaY as number)
        ), this.scaleHandler);

        const moveEvent = merge(
            // タッチパネル用
            fromEvent(this.container, "touchstart").pipe(
                // ゲストはズームさせるかどうかを切り替える必要があるため
                filter(_ => this.isZoomEnabled),
                filter(_ => this.drawingService.drawingModeType === DrawingModeType.Move),
                switchMap(() => fromEvent(window, "touchmove").pipe(
                    // 前回のイベントとセットで取得
                    pairwise(),
                    // 移動ベクトルを算出
                    map((e: [any, any]) => ({
                        movementX: -(e[0].changedTouches[0].clientX - e[1].changedTouches[0].clientX) as number,
                        movementY: -(e[0].changedTouches[0].clientY - e[1].changedTouches[0].clientY) as number
                    })),
                    takeUntil(fromEvent(document, "touchend"))
                ))
            ),
            // マウス用
            fromEvent(this.container, "mousedown").pipe(
                // ゲストはズームさせるかどうかを切り替える必要があるため
                filter(_ => this.isZoomEnabled),
                filter(_ => this.drawingService.drawingModeType === DrawingModeType.Move),
                switchMap(() => fromEvent(window, "mousemove").pipe(
                    map((e: any) => ({ movementX: e.movementX as number, movementY: e.movementY as number })),
                    takeUntil(fromEvent(document, "mouseup"))
                ))
            )
        );

        moveEvent.subscribe(e => {
            this.offsetX += e.movementX * 0.001;
            this.offsetY += e.movementY * 0.001;
        });

        merge(scaleEvent).subscribe(delta => {
            this.scale = Math.max(0.75, Math.min(this.scale - (delta * 0.0003), 2));
        });
        resizeEvent.subscribe(x => this.size = x);

        function subscribe(func: () => any): (x: Observable<unknown>) => Observable<unknown> {
            return (x) => {
                x.subscribe(func);
                return x;
            };
        }

        // 各イベントが発生したら（300ms以内に新しいイベントが発生したら現在のイベントはキャンセルする）
        merge(
            resizeEvent,
            this.resetHandler,
            scaleEvent,
            moveEvent,
            this.drawingService.recieveZoomHandler.pipe(
                x => {
                    x.subscribe(async e => {
                        this.drawingService.detach();
                        this.offsetY = e.offsetY;
                        this.offsetX = e.offsetX;
                        this.scale = e.scale;
                    });
                    return x;
                }
            )
        ).pipe(
            subscribe(() => animate(this.$refs.stage as any, "opacity", "0", 0.1)),
            switchMap(x => timer(300)
                .pipe(
                    map(() => x)
                )
            )
        ).subscribe(async _ => {
            const rect = this.drawAreaContent;
            this.drawingService.resize(this.size.width - rect.left - rect.right, this.size.height - rect.top - rect.bottom);
            this.drawingService.detach();
            await animate(this.$refs.stage as any, "opacity", "0", 0.1);
            await animate(this.$refs.stage as any, "opacity", "1", 0.25);
        });

        merge(
            scaleEvent,
            moveEvent,
            this.resetHandler
        ).pipe(
            switchMap(x => timer(300)
                .pipe(
                    map(() => x)
                )
            )
        ).subscribe(() => {
            this.drawingService.syncZoom({
                offsetX: this.offsetX,
                offsetY: this.offsetY,
                scale: this.scale
            });
        });

        // リサイズされるまで待つ
        this.loadImgHandler.pipe(concatMap(() => resizeEvent)).subscribe(() => {
            this.drawingService.fetchCurrentPage(this.docId, this.xdpId);
        });
    }

    private async mounted(): Promise<void> {
        this.drawingService.init(1, 1);
        this.initObservable();
        this.isInit = true;
        this.onImageSrcChanged();
    }

    private beforeDestroy() {
        window.removeEventListener("mousemove", this.onDrawMove);
        window.removeEventListener("mouseup", this.onEndDrawing);
    }

    private onBeginDraw(e: MouseEvent) {
        const rect = this.$el.getBoundingClientRect() as DOMRect;
        this.drawingService.beginDraw(e.x - rect.x, e.y - rect.y);
    }

    private onBeginDrawTouch(e: TouchEvent) {
        const rect = this.$el.getBoundingClientRect() as DOMRect;
        this.drawingService.beginDraw(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }

    private onEndDrawing() {
        this.drawingService.endDrawing();
    }

    private onDrawMove(e: MouseEvent) {
        const stage = this.$refs.stage as HTMLElement | null;
        if (!stage) return;
        const rect = stage.getBoundingClientRect() as DOMRect;
        this.drawingService.drawMove(e, rect);
    }

    private onDrawMoveTouch(e: TouchEvent) {
        const stage = this.$refs.stage as HTMLElement | null;
        if (!stage) return;
        const rect = stage.getBoundingClientRect() as DOMRect;
        this.drawingService.drawMove({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }, rect);
    }

    public removeCurrentShape() {
        this.drawingService.removeCurrentShape();
    }

    public removeAllShape() {
        this.drawingService.removeAllShape(this.docId, this.xdpId);
    }
}
