























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
import { ChatMessage } from "@/models/chat/ChatMessage";
import { IChatMessage } from "@/models/chat/IChatMessage";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { WebSocketService } from "@/models/web-socket/WebSocketService";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { ConnectionService } from "@/models/room/ConnectionService";
import { WebSocketMessage } from "../../../models/web-socket/WebSocketMessage";
import { ChatService } from "@/models/chat/ChatService";
import { v4 } from "uuid";

const hash = v4();

@Component
export default class ChatView extends Vue {
    private readonly chatService = container.resolve(ChatService);
    private readonly authService = container.resolve(AuthService);
    private readonly roomService = container.resolve(RoomInfoService);
    private readonly connectionService = container.resolve(ConnectionService);

    private clientHash = hash;

    /**
     * 送信するメッセージ
     */
    formMessage = "";

    /**
     * スクロールの高さ
     */
    scrollHeight = 0;

    isScrollBottom = false;

    unreadMessage?: { name: string; message: string } = undefined;

    websocket = {};
    rows = 1;

    send() {
        const message = this.formMessage.split("\n").join("<br>");

        this.roomService.sendHistory(message, this.connectionService.nickName);

        this.chatService.send({
            clientHash: this.clientHash,
            name: this.connectionService.nickName,
            message: this.formMessage,
            time: DateTime.local()
        });
        this.formMessage = "";
        this.rows = 1;
        this.scrollToBottom();
    }

    private parseDateTime(date: DateTime): string {
        const h = date.hour;
        let dh = h.toString();
        if (h < 10) {
            dh = "0" + h;
        }
        const m = date.minute;
        let dm = m.toString();
        if (m < 10) {
            dm = "0" + m;
        }
        return `${dh}:${dm}`;
    }

    /**
     * 最下部までスクロールしているかどうかを適応
     */
    applyScrollPosition() {
        const target = this.$refs.scroller as HTMLElement | undefined;
        if (!target) return;
        this.isScrollBottom =
            target.scrollHeight - target.scrollTop <= target.offsetHeight + 8;
        // 最下部まで来たとき未読メッセージがあれば消す
        if (this.isScrollBottom && this.unreadMessage) {
            this.unreadMessage = undefined;
        }
    }

    /**
     * 次の描画時に最下部までスクロール
     */
    scrollToBottom() {
        this.$nextTick(() => {
            const target = this.$refs.scroller as HTMLElement | undefined;
            if (!target) return;
            target.scrollTop = target.scrollHeight;
        });
    }


    private async  onMessageRecieved(message: IChatMessage) {
        // 未読メッセージへ登録
        this.unreadMessage = { name: message.name, message: message.message };
        // this.value++;
        // 受信したことを通知
        this.applyScrollPosition();
        if (this.isScrollBottom) {
            setTimeout(() => {
                this.scrollToBottom();
            }, 30);
        }
    }

    private async created(): Promise<void> {
        this.chatService.messageRecieved.add(this.onMessageRecieved);
    }

    private beforeDestroy() {
        this.chatService.messageRecieved.remove(this.onMessageRecieved);
    }

    private mounted() {
        this.applyScrollPosition();
    }
}
