















































































import { Vue, Component, Watch } from "vue-property-decorator";
import { DeviceStreamManager, DeviceMediaStreamWrapper, DeviceInfo } from "ui-gallery";
import { container } from "tsyringe";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import { ConnectionSetting, QualityType } from "@/models/connection/ConnectionSetting";

/**
 * 接続設定ダイアログを提供します.
 */
@Component
export default class ConnectionSettingDialog extends Vue {
    // #region private fields
    private webRtcService = container.resolve(WebRtcService);
    private isOpened = false;
    private connectionSetting?: ConnectionSetting = null as unknown as undefined;
    private resolve!: (value?: ConnectionSetting | PromiseLike<ConnectionSetting> | undefined) => void;
    // #endregion

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
    }

    public showAsync(connectionSetting: ConnectionSetting): Promise<ConnectionSetting | undefined> {
        this.connectionSetting = connectionSetting;
        this.isOpened = true;
        return new Promise<ConnectionSetting | undefined>(resolve => (this.resolve = resolve));
    }

    private ok() {
        this.isOpened = false;
        this.resolve(this.connectionSetting);
    }

    private cancel() {
        this.isOpened = false;
        this.resolve(undefined);
    }
}
