







































import { Vue, Component, Prop } from "vue-property-decorator";
import { container } from "tsyringe";
import { ScreenShareUseCase } from "@/models/webRtc/ScreenShareUseCase";
import { RoomInfoService } from "@/models/room/RoomInfoService";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import { ConnectionService } from "@/models/room/ConnectionService";
@Component
export default class ScreenShare extends Vue {
    private readonly screenShareUseCase: ScreenShareUseCase = container.resolve(ScreenShareUseCase);

    /**
     * ルーム情報に関するサービス
     */
    private readonly roomInfoService: RoomInfoService = container.resolve(RoomInfoService);

    /**
     * WebRTCに関するサービス
     */
    private readonly webRtcService: WebRtcService = container.resolve(WebRtcService);

    private readonly connecitionService: ConnectionService = container.resolve(ConnectionService);

    @Prop({ default: false }) readonly hideOperation !: boolean;

    @Prop({ default: false }) readonly noSuported !: boolean;

    // #region private getters

    // #region private methods
    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
        if (!this.hideOperation &&
            this.webRtcService.screenShareConnection &&
            !this.webRtcService.screenShareConnection.hasStream &&
            !this.webRtcService.screenShareConnection.remoteStreams.length) {
            // this.begin();
        }
    }

    private async begin(): Promise<void> {
        const isSuccess = await this.screenShareUseCase.begin();
        if (!isSuccess) {
            console.error("画面の共有に失敗しました");
            return;
        }
        this.roomInfoService.sendHistory(this.connecitionService.nickName + "さんが画面共有を開始しました", "システム");
    }

    private endScreenShare() {
        this.screenShareUseCase.end();
        this.roomInfoService.sendHistory(this.connecitionService.nickName + "さんが画面共有を終了しました", "システム");
    }

    private async anotherScreen(): Promise<void> {
        const isSuccess = await this.screenShareUseCase.another();
        if (!isSuccess) console.warn("別の画面の共有に失敗しました");
        this.roomInfoService.sendHistory(this.connecitionService.nickName + "さんが画面共有を終了しました", "システム");
        this.roomInfoService.sendHistory(this.connecitionService.nickName + "さんが画面共有を開始しました", "システム");
    }
    // #endregion
}
