































































import { Vue, Component, Watch, Inject, Prop } from "vue-property-decorator";

export enum ConfirmLeaveRoomRsultType {
    None,
    Destroy,
    Leave
}

@Component
export default class ConfirmLeaveRoomDialog extends Vue {
    private isShow = false;
    private resolve?: (value?: ConfirmLeaveRoomRsultType | PromiseLike<ConfirmLeaveRoomRsultType> | undefined) => void = undefined;
    private isFullScreen = false;
    private confirmLeaveRoomRsultType = ConfirmLeaveRoomRsultType;

    @Prop({ default: false }) readonly isExtern!: boolean;

    public showAsync(): Promise<ConfirmLeaveRoomRsultType> {
        this.isShow = true;
        return new Promise(resolve => {
            this.resolve = resolve;
        });
    }

    private close(result: ConfirmLeaveRoomRsultType) {
        this.isShow = false;
        if (this.resolve) {
            this.resolve(result);
        }
    }
    /*
    * コンポーネントが作成されたきに実行されます．
    */
    private created() {
        window.addEventListener("resize", this.onWindowSizeChanged);
        this.onWindowSizeChanged();
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onWindowSizeChanged);
    }

    private onWindowSizeChanged() {
        this.isFullScreen = window.innerWidth <= 520;
    }
}
