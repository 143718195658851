





























































































































































































































































































































































































import { Component, Watch, Vue, Mixins, Inject } from "vue-property-decorator";
import { Document } from "../../../models/documents/Document";
import { DocumentPage } from "../../../models/documents/DocumentPage";
import Axios from "axios";
import { DocumentManagementsService } from "@/models/documents/DocumentManagementsService";
import { DateTime } from "luxon";
import DocumentViewer from "../organisms/DocumentViewer.vue";
import { DialogBase, ItemWrapGrid, ContextMenu, FileDropAreaCompact, ConfirmDialog, ConfirmDialogContent, BrowserUtility, BrowserType } from "ui-gallery";
import { AuthService } from "@/models/auth/AuthService";
import InputPasswordDialog from "@/components/Commons/InputPasswordDialog.vue";
import { container } from "tsyringe";
import { config } from "@/config";

/**
 * 資料の選択ダイアログを提供します．
 */
@Component({ components: { ConfirmDialog, DocumentViewer, InputPasswordDialog, FileDropAreaCompact, ItemWrapGrid, ContextMenu } })
export default class DocumentSelectDialog extends Mixins<DialogBase<undefined, Document<DocumentPage>>>(DialogBase) {
    private isUploadMenuOpened: boolean | null = null;
    private isCreateNewGroupMenuOpened: boolean | null = null;
    private isShowViewer = false;
    currentFolder: any[] = [];
    open = ["public"];
    id = 4;
    contents = [];
    private document: Document<DocumentPage> | null = null;
    private newGroupName = "";
    private documentContextMenuItem: Document<DocumentPage> | null = null;
    private groupContextMenuItem: any | null = null;
    private file: File | null = null;
    private uploadGroup: string | null = "";
    private readonly documentManagementsService = container.resolve(DocumentManagementsService);
    private isLoading = false;
    private isFullScreen = false;
    private isSetPassword = false;
    private config = config;
    readonly authService = container.resolve(AuthService);

    get token() {
        return this.authService.token;
    }

    /**
     * ファイルアップロードメニューが開いたり閉じたりしたときに実行されます．
     */
    @Watch("isUploadMenuOpened")
    private onIsUploadMenuOpenedChanged() {
        this.file = null;
    }

    /**
     * ファイルアップロードメニューが開いたり閉じたりしたときに実行されます．
     */
    @Watch("isCreateNewGroupMenuOpened")
    private onIsCreateNewGroupMenuOpened() {
        this.newGroupName = "";
    }

    /**
     * アップロードするグループに初期値をセットします．
     */
    @Watch("documentManagementsService.documentGroups")
    private onDocumentGroupsChanged() {
        if (this.uploadGroup === "" && this.documentManagementsService.documentGroups.length > 0) {
            this.uploadGroup = this.documentManagementsService.documentGroups[0].dgId;
        }
    }

    /**
     * ダイアログが開いたり閉じたりしたときに実行されます．
     */
    @Watch("isShow")
    private onIsShowChanged(value: boolean) {
        if (value) {
            this.document = null;
            const grid = this.$refs.grid as ItemWrapGrid | undefined;
            if (!grid) return;
            grid.$forceUpdate();
        }
    }

    @Inject()
    private input!: (message: string) => Promise<string>;

    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => void;

    /**
     * コンポーネントが作成されたときに実行されます．
     */
    private async created(): Promise<void> {
        window.addEventListener("resize", this.onWindowSizeChanged);
        this.onWindowSizeChanged();
        await this.documentManagementsService.fetchDocumentGroupsAsync();
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onWindowSizeChanged);
    }

    private onWindowSizeChanged() {
        this.isFullScreen = window.innerWidth <= 520 || BrowserUtility.hasFlag(BrowserType.ios);
    }

    /**
     * パスワードを変更します
     */
    private async onChangePassword() {
        if (this.documentContextMenuItem) {
            const InputPasswordDialog = this.$refs.InputPasswordDialog as InputPasswordDialog;
            const result = await InputPasswordDialog.showAsync() as { oldPassword: string; newPassword: string };
            if (result) {
                const isSuccess = await this.documentManagementsService.changePasswordAsync(this.documentContextMenuItem.docId, result.oldPassword, result.newPassword);
                this.isLoading = true;
                await this.documentManagementsService.fetchDocumentGroupsAsync();
                if (isSuccess) {
                    this.notify("パスワードを変更しました", 4000);
                }
                else {
                    this.notify("パスワードの変更に失敗しました", 4000, "error");
                }
                this.isLoading = false;
            }
        }
    }

    /**
     * アップロードボタンがクリックされたときに実行されます．
     */
    private async onUploadClicked(): Promise<void> {
        this.isUploadMenuOpened = false;
        if (this.file && this.uploadGroup) {

            try {
                if (this.isSetPassword) {
                    const InputPasswordDialog = this.$refs.InputPasswordDialog as InputPasswordDialog;
                    const result = await InputPasswordDialog.showAsync() as { oldPassword: string; newPassword: string };
                    await this.documentManagementsService.uploadFileAsync(this.file, this.uploadGroup, result.newPassword);
                }
                else {
                    this.isLoading = true;
                    const isSuccess = await this.documentManagementsService.uploadFileAsync(this.file, this.uploadGroup, "");
                }
                await this.documentManagementsService.fetchDocumentGroupsAsync();
                this.notify("資料をアップロードしました", 4000);
            }
            catch (ex) {
                this.notify("資料のアップロードに失敗しました", 4000, "error");
                logger.error("資料のアップロードに失敗しました", ex);
                console.error("資料のアップロードに失敗しました", ex);
            }
            finally {
                this.isLoading = false;
            }
        }
    }

    /**
     * ファイルピッカーからファイルを受け取ったとき．
     */
    private onRecieved(file: File) {
        this.file = file;
    }

    /**
     * 資料のサムネイルをクリックしたときに実行されます．
     */
    private async onDocumentSelected(doc: Document<DocumentPage>): Promise<void> {
        this.document = new Document();
        this.document = await this.documentManagementsService.fetchDocumentAsync(doc.docId) || null;
    }

    /**
     * 日付をフォーマットします．
     */
    private getDate(date: string) {
        return DateTime.fromISO(date).toFormat("yyyy年MM月dd日");
    }

    /**
     * グループを選択してアクティブにします．
     */
    private onGroupSelected(group: any) {
        this.uploadGroup = group.dgId;
        this.documentManagementsService.activeGroup = group;
    }

    /**
     * フォルダ名をの新規作成ボタンを押したときに実行されます．
     */
    private async onCreateNewGroupClicked(): Promise<void> {
        this.isCreateNewGroupMenuOpened = false;
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.createNewGroupAsync(this.newGroupName);
        await this.documentManagementsService.fetchDocumentGroupsAsync();
        if (isSuccess) {
            this.notify("フォルダを作成しました", 4000);
        }
        else {
            this.notify("フォルダを作成に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    // #region context nemu
    /**
     * コンテキストメニューを表示します．
     * @param e マウスイベント情報ß
     * @param doc クリックした資料
     */
    private onShowDocumentContextMenu(e: MouseEvent, doc: Document<DocumentPage>) {
        this.documentContextMenuItem = doc;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            menu.show(e.x - 240, e.y - 40);
        }
    }

    /**
     * グループのコンテキストメニューを表示します．
     * @param e マウスイベント情報
     * @param doc クリックした資料
     */
    private onShowGroupContextMenu(e: MouseEvent, group: any) {
        this.groupContextMenuItem = group;
        const groupMenu = this.$refs.groupMenu as ContextMenu | undefined;
        if (groupMenu) {
            groupMenu.show(e.x, e.y);
        }
    }

    /**
     * グループの削除イベントを発火させます．
     */
    private async onRemoveGroup(): Promise<void> {
        this.closeContextMenu();
        const dialog = this.$refs.confirmDialog as any | undefined;
        if (!(await dialog.showAsync(new ConfirmDialogContent({
            title: `フォルダ ${this.groupContextMenuItem.name} と含まれる資料を全て削除しますか？`
        })))) return;

        if (this.groupContextMenuItem) {
            this.isLoading = true;
            const isSuccess = await this.documentManagementsService.removeGroup(this.groupContextMenuItem.dgId);
            await this.documentManagementsService.fetchDocumentGroupsAsync();
            if (isSuccess) {
                this.notify("フォルダを削除しました", 4000);
            }
            else {
                this.notify("フォルダの削除に失敗しました", 4000, "error");
            }
            this.isLoading = false;
        }
    }

    private async onRenameDocument(): Promise<void> {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            const name = await this.input("新しい資料名を入力してください");
            if (name) {
                if (name) {
                    this.isLoading = true;
                    const isSuccess = await this.documentManagementsService.renameDocument(name, "");
                    await this.documentManagementsService.fetchDocumentGroupsAsync();
                    if (isSuccess) {
                        this.notify("資料名を変更しました", 4000);
                    }
                    else {
                        this.notify("資料名の変更に失敗しました", 4000, "error");
                    }
                    this.isLoading = false;
                }
            }
        }
    }

    /**
     * フォルダ名を変更します．
     */
    private async onRenameGroup(): Promise<void> {
        this.closeContextMenu();
        if (this.groupContextMenuItem) {
            const name = await this.input("新しいフォルダ名を入力してください");
            if (name) {
                this.isLoading = true;
                const isSuccess = await this.documentManagementsService.renameGroup(name, "");
                await this.documentManagementsService.fetchDocumentGroupsAsync();
                if (isSuccess) {
                    this.notify("フォルダ名を変更しました", 4000);
                }
                else {
                    this.notify("フォルダ名の変更に失敗しました", 4000, "error");
                }
                this.isLoading = false;
            }
        }
    }

    /**
     * 資料をプレビューします．
     */
    private async onPreview(): Promise<void> {
        this.closeContextMenu();
        if (this.documentContextMenuItem) {
            this.document = await this.documentManagementsService.fetchDocumentAsync(this.documentContextMenuItem.docId) || null;
        }
    }

    /**
     * 資料を削除します．
     */
    private async onRemoveDocument(): Promise<void> {
        this.closeContextMenu();
        const dialog = this.$refs.confirmDialog as any | undefined;

        if (this.documentContextMenuItem && dialog) {
            const isRemove = await dialog.showAsync(new ConfirmDialogContent({
                title: `資料 ${this.documentContextMenuItem.name} を削除しますか？`,
                cancelText: "キャンセル",
                okText: "削除する"
            }));
            if (!isRemove) return;

            this.isLoading = true;
            const isSuccess = await this.documentManagementsService.removeDocumentAsync(this.documentContextMenuItem.docId);
            await this.documentManagementsService.fetchDocumentGroupsAsync();

            if (isSuccess) {
                this.notify("資料を削除しました", 4000);
            }
            else {
                this.notify("資料の削除に失敗しました", 4000, "error");
            }
            this.isLoading = false;
        }
    }

    /**
     * コンテキストメニューを閉じます．
     */
    private closeContextMenu() {
        const groupMenu = this.$refs.groupMenu as ContextMenu | undefined;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu && groupMenu) {
            menu.close();
            groupMenu.close();
        }
    }
    // #endregion
}
