





import { Vue, Component } from "vue-property-decorator";
import TimelinePanel from "@/components/Connections/molecles/TimelinePanel.vue";
/**
 * タイムラインを提供します.
 */
@Component({ components: { TimelinePanel } })
export default class TimelineView extends Vue {

}
