




























































































































































import { Vue, Prop, Component, Provide, Inject } from "vue-property-decorator";
import { Profile } from "@/models/profile/Profile";
import { ItemWrapGrid, ContextMenu } from "ui-gallery";
import Axios from "axios";
import { SuccessResponse } from "../../../models/api/Response";
import { ITemplate, ITemplateContent } from "@/models/profile";
import { copyToClipBoard } from "@/models/utilities/clip-board";
import { config } from "@/config";

/**
 * プロフィールを一覧表示して各種操作をするコントロールを提供します．
 */
@Component({ components: { ItemWrapGrid, ContextMenu } })
export default class extends Vue {
    /**
     * リストビューに表示するプロフィール
     */
    @Prop({ default: [] })
    readonly profiles!: Profile[];

    /**
     * 相手へ共有するボタンを表示するかどうか
     */
    @Prop({ default: true })
    readonly isShowShare!: boolean;

    /**
     * 履歴かどうか
     */
    @Prop({ default: false }) readonly isHistory!: boolean;

    /**
     * 右クリックしたプロフィールのコンテキスト
     */
    private contextMenuItem?: Profile = undefined;

    /**
     * メッセージを通知します．
     */
    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;

    private config = config;

    /**
     * コンポーネントのViewが更新されたときに実行されます．
     */
    public updated() {
        const grid = this.$refs.grid as ItemWrapGrid | undefined;
        if (!grid) return;
        grid.$forceUpdate();
    }

    /**
     * プロフィールが選択されたときに実行されます．
     */
    private invokeProfile() {
        this.$emit("invokeProfile", this.contextMenuItem);
    }

    /**
     * プレビューをクリックしたときに実行されます．
     */
    private onPreviewClicked() {
        this.$emit("preview", this.contextMenuItem);
    }
    // #region helpers
    /**
     * テンプレートの子プロパティからコンテントを取得します．
     * @description viewに表示するためのショートハンドメソッドとして利用．
     * @param 取得する元のテンプレート
     * @returns テンプレートコンテント
     */
    private getTemplateContent(template?: ITemplate<ITemplateContent>): ITemplateContent | {} {
        if (template) {
            return template.content;
        }
        return {};
    }
    // #endregion

    // #region context menu commands
    /**
     * クリップボードに公開ページのURLをコピーします．
     */
    private copyToClipBoard() {
        if (!this.contextMenuItem) return;
        copyToClipBoard(config.camelUrl + "/api/profiles/render/" + this.contextMenuItem.key);
        this.notify("プロフィールのURLをコピーしました");
        this.closeContexMenu();
    }

    /**
     * 公開ページを開きます．
     */
    private openPublishPage() {
        if (!this.contextMenuItem) return;
        window.open(config.camelUrl + "/api/profiles/render/" + this.contextMenuItem.key);
        this.closeContexMenu();
    }

    /**
     * マウスイベントの位置にコンテキストメニューを表示します．
     * @param e マウスイベント情報
     * @param クリックしたプロフィール
     */
    private onShowContextMenu(e: MouseEvent, profile: Profile) {
        this.contextMenuItem = profile;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            // 画面からはみ出ないようにする
            menu.show(e.x - 240, e.y - 40);
        }
    }

    /**
     * コンテキストメニューを閉じます．
     */
    private closeContexMenu() {
        this.contextMenuItem = undefined;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            menu.close();
        }
    }
    // #endregion
}
