




























import { Vue, Component } from "vue-property-decorator";

/**
 * タイムラインを一覧表示するためのコントロールを提供します.
 */
@Component
export default class TimelineView extends Vue {
    private items = [
        {
            id: 1,
            color: "warning",
            icon: "chat"
        }
    ];
}
