













































































import Axios from "axios";
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { Profile } from "@/models/profile/Profile";
import { copyToClipBoard } from "@/models/utilities/clip-board";
import { config } from "@/config";
import { BrowserUtility, BrowserType } from "ui-gallery";

/**
 * プロフィールをプレビュー表示するためのダイアログを提供します．
 */
@Component({ components: {} })
export default class ProfileDisplayDialog extends Vue {
    // #region private fields
    private isShow = false;
    private resolve!: (value?: void | PromiseLike<void> | undefined) => void;
    private renderedDom = "";
    private profile?: Profile;
    private isHideSendButton = false;
    private isRendered = false;
    // #endregion

    get isMobile() {
        return window.innerWidth < 600;
    }

    // #region components properites
    @Prop({ default: 480 }) readonly itemSize!: number;

    /**
     * メッセージを通知します．
     */
    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;
    // #endregion

    // #region private methods
    /**
     * ダイアログを開きます．
     */
    public async showAsync(profile: Profile, isHideSendButton = false): Promise<void> {
        this.isHideSendButton = isHideSendButton;
        this.isRendered = false;
        this.renderedDom = (await this.fetchProfileDomAsync(profile)).map(x => `<div class="profile">${x}</div>`).join("");
        this.isShow = true;
        this.isRendered = true;
        this.profile = profile;

        return new Promise<void>(resolve => (this.resolve = resolve));
    }

    /**
     * ダイアログを閉じます．
     */
    private close() {
        this.isShow = false;
        this.resolve();
    }

    /**
     * 送信リクエストを発火させます．
     */
    private send(profile: Profile) {
        this.$emit("send", profile);
    }

    /**
     * クリップボードにURLをコピーします
     */
    private copyToClipBoard() {
        if (!this.profile) return;
        copyToClipBoard(config.camelUrl + "/api/profiles/render/" + this.profile.key);
        this.notify("プロフィールのURLをコピーしました");
    }

    /**
     * 公開ページを開きます．
     */
    private openPublishPage() {
        if (!this.profile) return;
        window.open(config.camelUrl + "/api/profiles/render/" + this.profile.key);
    }

    /**
     * SSR済みのプロフィールを取得します．
     */
    private async fetchProfileDomAsync(profile: Profile): Promise<string[]> {
        try {
            const response = await Axios.get<string[]>(config.camelUrl + "/api/profiles/render_by_id/" + profile.profileId, {
                params: {
                    content: 1
                }
            });
            return response.data;
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
        return [];
    }
    // #endregion
}
