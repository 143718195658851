import { RoomInfoService } from "@/models/room/RoomInfoService";
import { WebRtcService } from "@/models/webRtc/WebRtcService";
import {
    DeviceStreamManager,
    RtcMediaStreamWrapper,
    DeviceMediaStreamWrapper,
    getUrlParameter
} from "ui-gallery";
import { ScreenShareRoom } from "./ScreenShareRoom";
import { ConnectionSetting } from "../connection/ConnectionSetting";
import { inject, injectable, singleton } from "tsyringe";
import { from } from "linq";

/**
 * 画面共有に関するユースケースを提供します.
 */
@singleton()
export class ScreenShareUseCase {
    // #region public methods
    public constructor(
        @inject(RoomInfoService) private readonly roomInfoService: RoomInfoService,
        @inject(WebRtcService) private readonly webRtcService: WebRtcService) {
    }

    /**
     * 画面共有へ接続します.
     * @description デバイスの有効性は、GETパラメータにデバイスIDがセットされていれば有効、
     * されていなければ無効となります。
     */
    public async connect(apiKey?: string): Promise<boolean> {
        try {
            const connection = new ScreenShareRoom(apiKey || "792359d7-d78b-4ab1-9c7d-0dae465e09df", this.webRtcService.connectionSetting.roomId.toString());
            this.webRtcService.setScreenShareConnection(connection);
            await connection.connect();
            return true;
        }
        catch (ex) {
            logger.error("画面共有の接続に失敗しました。", ex);
        }
        return false;
    }

    /**
     * 再度画面共有を開始します.
     */
    public async begin(): Promise<boolean> {
        try {
            const stream = await DeviceStreamManager.getScreenShareStream();
            if (!stream || !this.webRtcService.screenShareConnection) return false;
            await this.webRtcService.screenShareConnection.beginScreenShare(stream);
            // 送信用ストリームをサービスへ保存
            this.webRtcService.setScreenShareTransmitStream(stream);
            return true;
        }
        catch (ex) {
            logger.error("画面共有の接続に失敗しました。", ex);
            console.error("画面共有の接続に失敗しました。", ex);
            alert("画面共有の接続に失敗しました");
        }
        return false;
    }

    public end() {
        if (!this.webRtcService.screenShareConnection) return;
        this.webRtcService.screenShareConnection.endScreenShare();
        const stream = this.webRtcService.screenShareTransmitStream as DeviceMediaStreamWrapper;
        DeviceStreamManager.disposeAt(stream);
        this.webRtcService.setScreenShareTransmitStream(new RtcMediaStreamWrapper(new MediaStream(), false));
    }

    public async another(): Promise<boolean> {
        try {
            if (!this.webRtcService.screenShareConnection) return false;
            const stream = await DeviceStreamManager.getScreenShareStream();
            if (!stream || !this.webRtcService.screenShareConnection) return false;
            this.end();
            await this.webRtcService.screenShareConnection.beginScreenShare(stream);
            // 送信用ストリームをサービスへ保存
            this.webRtcService.setScreenShareTransmitStream(stream);
            return true;
        }
        catch (ex) {
            logger.error("画面共有の接続に失敗しました。", ex);
        }
        return false;
    }
    // #endregion
}
