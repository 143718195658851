var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 white--text",staticStyle:{"color":"white"},attrs:{"small":"","color":_vm.value===_vm.screenModeType.ScreenShare?'primary':'#21212187',"fab":""},on:{"click":function($event){return _vm.$emit('input',_vm.screenModeType.ScreenShare)}}},on),[_c('v-icon',[_vm._v("mdi-monitor-screenshot")])],1)]}}])},[_c('span',[_vm._v("画面共有")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isShowVideoChatModeButton)?_c('v-btn',_vm._g({staticClass:"mt-2 white--text",staticStyle:{"color":"white"},attrs:{"color":_vm.value===_vm.screenModeType.VideoChat?'primary':'#21212187',"fab":"","small":""},on:{"click":function($event){return _vm.$emit('input',_vm.screenModeType.VideoChat)}}},on),[_c('v-icon',[_vm._v("mdi-video-account")])],1):_vm._e()]}}])},[_c('span',[_vm._v("ビデオ会議")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 white--text",attrs:{"color":_vm.value===_vm.screenModeType.DocumentShare?'primary':'#21212187',"fab":"","small":""},on:{"click":function($event){return _vm.$emit('input',_vm.screenModeType.DocumentShare)}}},on),[_c('v-icon',[_vm._v("mdi-text-box-multiple-outline")])],1)]}}])},[_c('span',[_vm._v("資料共有")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 white--text",attrs:{"color":'#21212187',"fab":"","small":""},on:{"click":function($event){return _vm.$emit('showProfile',_vm.showProfile)}}},on),[_c('v-icon',[_vm._v("mdi-account-box")])],1)]}}])},[_c('span',[_vm._v("プロフィール共有")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }